<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>
      <div slot="bottom_box_content">
        <component :is="curBottomComponent" :role="2"></component>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    // 底部组件
    IndexTable: () => import('./components/IndexTable')
  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setShowRightBlock(false)
    this.setShowMap(false)
    this.setBottomHeight('90%')
    this.setBottomComponent('IndexTable')
  }
}
</script>
